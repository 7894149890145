<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2024-02-01 10:05:02
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-12-19 16:33:27
-->
<template>
  <!-- 电脑视图-内容-开始 -->
  <div style="width:60%;height:550px;margin:0px auto;overflow-y: scroll;">
    <!-- 主要内容-题目--开始 -->
    <div class="paper-topic-content" v-if="questionsGroupListCopy.length" id="dynamic-table-page">
      <div v-for="(questionsGroup,index) in questionsGroupListCopy" :key="index">
        <!-- <div v-if="questionsGroup.groupName">
          <div style="display:flex; justify-content:space-between;">
              <com-title :title="questionsGroup.groupName" />
          </div>
        </div> -->
        <div class="containerBlock">
          <div v-for="(item, index) in questionsGroup?.questionInfo||[]" :key="index" class="topic">
            <div style="padding:16px;">
              <div class="title">
                {{ item.currentIndexSort}}.<span class="type">「{{ getTypeValue(item.questionType) }}」</span
                >{{ item.title }}<span class="grade">（{{ item.score }}分）</span>
              </div>
              <div class="explain">题目说明：{{ item.content }}</div>
              <!-- 题目说明---上传图片 开始-->
              <div v-if="item?.contentUrl!==''&&item?.contentUrl.length!==0&&item?.contentUrl[0]!==''">
                <el-image v-for="(item,index) in item?.contentUrl" :key="index" :src="item" style="margin-right:10px;width:100px;height:100px;" :preview-src-list="[item]"></el-image>
              </div>
              <!-- 题目说明---上传图片--结束 -->
              <!-- 单选题&&判断题 start -->
              <el-radio-group v-model="item.answer" v-if="item.questionType === 'SINGLE_CHOICE_TYPE' || item.questionType ==='JUDGMENT_TYPE'" class="prohibit-click">
                <el-radio
                  v-for="(ele, index) in item.options"
                  :key="index"
                  :label="`选项${index + 1}`"
                  >{{ ele.optionName }}</el-radio>
              </el-radio-group>
              <!-- 单选题&&判断题 end -->
              <!-- 多选题 start -->
              <el-checkbox-group
                class="prohibit-click"
                v-model="item.answerCheck"
                v-if="item.questionType == 'MULTIPLE_CHOICE_TYPE'"
              >
                <el-checkbox
                  v-for="(ele, index) in item.options"
                  :key="index"
                  :label="`选项${index + 1}`"
                  >{{ ele.optionName }}</el-checkbox>
              </el-checkbox-group>
              <!-- 多选题 end -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <span style="margin: 20px;" v-else>暂无题目，请添加！</span>
    <!-- 主要内容-题目--结束 -->
  </div>
  <!-- 电脑视图-内容-结束 -->
</template>
<script>
export default {
  props: {
    questionsGroupList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      basicInfo: {}, // 基本信息
      percentageScore: 0,
      allTopicListCopy: [], // 所有题目
      flag: false,
      allTopicList: [], // 所有题目
      allTopicCardList: [], // 答题卡所需的是否已答
      questionsGroupListCopy: []

    }
  },
  mounted () {
  },
  created () {
    if (this.questionsGroupList?.length !== 0) {
      this.questionsGroupList.forEach((groupInfo, groupIndex) => {
        this._getNeedShowData(this.questionsGroupList, groupInfo, groupIndex)// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
      })
    }
    const tempQuestionAllArr = JSON.parse(
      JSON.stringify(this.questionsGroupList)
    )
    const tempAllTopicList = []
    tempQuestionAllArr.forEach((resOne) => {
      tempAllTopicList.push(...resOne.questionInfo)
    })
    this.allTopicList = JSON.parse(JSON.stringify(tempAllTopicList))
    this.allTopicList.forEach((itemAll, indexAll) => { // 添加每道题的序号
      itemAll.currentIndexSort = indexAll + 1
    })
    // 当前激活的题
    this.allTopicList[0].currentActive = true
    // 展示在答题卡中的题目
    this.questionsGroupListCopy = this._handlerDatas(this.allTopicList)
  },
  methods: {
    // 试卷-- 回显所需数据格式
    _getNeedShowData (groupTopicList, groupInfo, groupIndex) {
      groupInfo.groupIndex = groupIndex
      groupTopicList[groupIndex].groupTopicNumber = groupTopicList[groupIndex].questionInfo.length
      groupTopicList[groupIndex].groupTopicSumScore = this._getCountGroupTopicSumScore(groupTopicList[groupIndex].questionInfo)
      groupTopicList[groupIndex].questionInfo = groupTopicList[groupIndex].questionInfo.map((topicItem, topicIndex) => {
        return {
          groupName: groupTopicList[groupIndex].groupName,
          groupSort: groupTopicList[groupIndex].groupSort,
          groupTopicNumber: groupTopicList[groupIndex].groupTopicNumber,
          groupTopicSumScore: groupTopicList[groupIndex].groupTopicSumScore,
          ...topicItem,
          index: topicIndex,
          groupIndex: groupIndex,
          mark: topicItem.testerMark,
          correctStatus: topicItem.correctStatus,
          show: false
        }
      })
    },
    // 计算每个分组的总分
    _getCountGroupTopicSumScore (questionInfo) {
      let tempScore = 0
      questionInfo.forEach((item) => {
        tempScore = tempScore + Number(item.score)
      })
      return tempScore
    },
    // 将数据对象根据某一相同key合并成新的数组
    _handlerDatas (arr) {
      const obj = {}
      arr.forEach((item, index) => {
        const { groupIndex } = item
        const { groupName } = item
        const { groupSort } = item
        const { groupTopicNumber } = item
        const { groupTopicSumScore } = item
        if (!obj[groupIndex]) {
          obj[groupIndex] = {
            groupIndex,
            groupName,
            groupSort,
            groupTopicNumber,
            groupTopicSumScore,
            questionInfo: []
          }
        }
        obj[groupIndex].questionInfo.push(item)
      })
      const data = Object.values(obj) // 最终输出
      return data
    },
    getTypeValue (type) {
      let value
      switch (type) {
        case 'SINGLE_CHOICE_TYPE':
          value = '单项选择'
          break
        case 'MULTIPLE_CHOICE_TYPE':
          value = '多项选择'
          break
        case 'JUDGMENT_TYPE':
          value = '判断题'
          break
        case 'GAP_FILLING_TYPE':
          value = '填空题'
          break
        case 'ESSAY_QUESTION_TYPE':
          value = '问答题'
          break
        default:
          break
      }
      return value
    }
  }
}
</script>
<style lang="scss" scoped>
.paper-topic-content{
  text-align: left;
  /deep/.el-radio__input{
    display: none!important;
  }
  /deep/.el-radio__label{
    color: #333;
    line-height: 20px;
    padding-left: 0px;
  }
  /deep/.el-radio{
    padding: 12px 12px!important;
    border-radius: 4px;
    background-color: #f6f7f9 !important;
    margin-bottom: 10px;
    margin-right: 0px;
    white-space: normal!important;
  }
  /deep/.is-checked{
    background-color: #f0feed !important;
    color: #333;
  }
  /deep/.el-radio__input.is-checked+.el-radio__label{
    background-color: #f0feed !important;
    color: #333;
  }
  // 多选题
  /deep/.el-checkbox__input{
    display: none!important;
  }
  /deep/.el-checkbox__label{
    color: #333;
    line-height: 20px;
    padding-left: 0px;
  }
  /deep/.el-checkbox{
    padding: 12px 12px!important;
    border-radius: 4px;
    background-color: #f6f7f9 !important;
    margin-bottom: 10px;
    margin-right: 0px;
    white-space: normal!important;
  }
  /deep/.is-checked{
    background-color: #f0feed !important;
    color: #333;
  }
  /deep/.el-checkbox__input.is-checked+.el-checkbox__label{
    background-color: #f0feed !important;
    color: #333;
  }
}

.title {
  .type {
    color: #0089ff;
    font-size: 14px;
  }
  color: #000;
  font-size: 16px;
  .grade {
    color: #666;
    font-size: 12px;
  }
}
.explain {
  color: #999;
  font-size: 14px;
  margin: 16px 0;
}
.analysis {
  background: #f6f7f9;
  padding: 10px 12px;
  font-size: 14px;
  span {
    color: #999;
    margin-right: 8px;
    line-height: 25px;
  }
}
.handle-btn {
  font-size: 14px;
  > div {
    margin-left: 24px;
    cursor: pointer;
  }
  i {
    margin-right: 5px;
  }
}
.el-radio-group,.el-checkbox-group{
  display: flex;
  flex-direction: column;
  /deep/.el-radio,/deep/.el-checkbox{
    padding: 16px 0px 1px 0px;
  }
}
// 视图样式
.view-type-style{
  display:flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
  .computer-view{
    margin-right: 10px;
  }
}
/deep/.el-radio-button__inner{
  border-radius: 4px!important;
  border: 1px solid #DCDFE6;
  background: #DCDFE6;
  font-size: 14px!important;
}
.phone-view-content{
  text-align: center;
  .img-style{
    img{
      width:400px;
    }
  }
  .text-style{
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: rgba($color: #000000, $alpha: 0.9);
  }
}
</style>
