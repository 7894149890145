<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-12-20 10:37:37
-->
<template>
  <div class="container-warp">
    <!-- 头部卡片--开始 -->
      <el-card class="card-pd" shadow='never'>
        <!-- 搜索区域--开始 -->
        <div slot="header">
          <el-form  class="form-item-no-mb" :model="searchForm"  ref="searchForm" inline >
            <el-form-item label="试卷名称">
              <el-input style="width:275px;" v-model="searchForm.name" placeholder="请输入试卷名称" clearable></el-input>
            </el-form-item>
            <el-form-item label="创建时间">
              <el-date-picker
                style="width:275px;"
                v-model="createTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
            <el-button @click="_reset">重置</el-button>
            <el-button type="primary" @click="dataInit">查询</el-button>
          </el-form>
        </div>
        <!-- 搜索区域--结束 -->
        <div class="create-exam">
          <el-button type="primary" @click="_createExamPaper">创建试卷</el-button>
        </div>
      </el-card>
      <!-- 头部卡片--结束 -->
      <!-- 试卷列表--开始 -->
      <div class="fx-row mt16 bac-fff fx-1">
        <!-- 试卷分组--开始 -->
        <ResourceGrouping
          :treeData="treeData"
          :title="titleText"
          :defaultProps="defaultProps"
          @editGroupHandle="_editGroupHandle"
          @deleteGrouphandle="_deleteGrouphandle"
          @addGroupHandle="_addGroupHandle"
          @clickGroupNode="_clickGroupNode"
        />
        <!-- 试卷分组--结束 -->
        <!-- 表格 -->
        <div class="w200 fx-1">
          <el-tabs v-model="activePaperName"  @tab-click="_tabClick" :style="{'--font-Color' : fontColor}">
            <el-tab-pane v-for="(item, index) in tabList" :key='index' :label="`${item.title}（${item.num}）`" :name='item.name' class="paper-tab-pane">
                <div :key="refreshTable">
                  <el-table
                    :header-cell-style="cellHeaderStyle"
                    :cell-style="cellStyle"
                    class="index-list-table"
                    :data="paperInfoList"
                    height="calc(100vh - 400px)"
                    v-loading="tableLoading">
                    <el-table-column prop="name" label="试卷名称">
                      <template slot-scope="scope">
                        {{scope.row.name||'—'}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="questionNumber" label="题目数量">
                      <template slot-scope="scope">
                        {{scope.row.questionNumber||'—'}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="mark" label="总分">
                      <template slot-scope="scope">
                        {{scope.row.mark||'—'}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="description" label="试卷描述">
                      <template slot-scope="scope">
                        {{scope.row.description||'—'}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="groupName" label="所属分组">
                      <template slot-scope="scope">
                        {{scope.row.groupName||'—'}}
                      </template>
                    </el-table-column>
                    <el-table-column prop="statusValue" label="试卷状态">
                      <template slot-scope="scope">
                        {{scope.row.statusValue||'—'}}
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" :width="activePaperName==1?'280px':'230px'" fixed="right">
                      <template slot-scope="scope">
                        <el-button type="text" class="btn-style" @click="_paperDetailEditBtn(scope.row, scope.$index)">详情</el-button>
                        <el-button type="text" @click="_previewPaper(scope.row, scope.$index)">预览</el-button>
                        <el-button type="text" @click="_updatePaperStatusBtn(scope.row, '2')" v-if="activePaperName==1">停用</el-button>
                        <el-button type="text" @click="_arrangeExamBtn(scope.row)" v-if="activePaperName==1">安排考试</el-button>
                        <el-button type="text" @click="_updatePaperStatusBtn(scope.row, '1')" v-if="activePaperName==2">重新发布</el-button>
                        <el-button type="text" @click="_paperDeleteBtn(scope.row, scope.$index)">删除</el-button>
                        <el-button type="text" v-if="activePaperName==0" @click="_updatePaperStatusBtn(scope.row, '1')">发布试卷</el-button>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
            </el-tab-pane>
          </el-tabs>
          <el-pagination
            class="fx-row-end pt16"
            style="padding-bottom:16px;"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[5, 10, 20, 30, 40]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="paperTotal">
          </el-pagination>
        </div>
      </div>
      <!-- 试卷列表---结束 -->
      <!-- 创建试卷弹框--开始 -->
      <addPaperDrawer
        v-if="addPaperShow"
        :addPaperShow="addPaperShow"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @submitAddPaper="_submitAddPaper"
        ref="addPaperDrawer"
      ></addPaperDrawer>
      <!-- 创建试卷弹框--结束 -->
      <!-- 试卷分组新增 -->
      <AddGroup
        v-if="addGroupShow"
        :addGroupShow="addGroupShow"
        :treeData="treeData"
        :defaultProps="defaultProps"
        @submiAddGroup="_submiAddGroup"
        ref="addGroup"
      ></AddGroup>
      <!-- 试卷分组编辑 -->
      <EditGroup
        v-if="editGroupShow"
        ref="editGroup"
        :editGroupShow="editGroupShow"
        :editDataInfo="editDataInfo"
        :treeData="treeDataGroup"
        :defaultProps="defaultProps"
        :currentNode="currentNode"
        @editSubmitGroup="_editSubmitGroup"
        pageValueType='normalGroup'
      ></EditGroup>
      <!-- 分组删除 -->
      <DeleteGroup v-if="deleteGroupShow" :deleteGroupShow="deleteGroupShow" />
      <!-- 预览试卷--弹框--开始 -->
      <previewExamPaper
        v-if="previewExamShow"
        :previewExamShow="previewExamShow"
        :paperId="paperId"
        :paperName="paperName"
        :questionsGroupList="questionsGroupList"
      ></previewExamPaper>
      <!-- 预览试卷--弹框--结束 -->
  </div>
</template>
<script>
import {
  getPaperListApi, getPaperStatusNumberApi, getPaperManyDeleteApi, getPaperEditApi,
  getPaperGroupDetailApi, getPaperGroupTreeListApi, getPaperGroupAddApi, getPaperGroupEditApi, getPaperGroupDeleteApi,
  getPaperDetailApi
} from '@/api/examApi'
import addPaperDrawer from './components/addPaperDrawer.vue'
import deleteFinalData from '@/mixins/deleteFinalData'
import ResourceGrouping from '@/components/resourceGrouping/index.vue'
import AddGroup from '@/components/addGroup/index.vue'
import EditGroup from '@/components/editGroup/index.vue'
import tableFirstCol from '@/mixins/tableFirstCol'
import previewExamPaper from './modules/previewExamPaper.vue'
export default {
  components: {
    addPaperDrawer,
    ResourceGrouping,
    AddGroup,
    EditGroup,
    previewExamPaper
  },
  mixins: [tableFirstCol, deleteFinalData],
  data () {
    return {
      titleText: '试卷分组',
      addGroupShow: false,
      editGroupShow: false,
      deleteGroupShow: false,
      treeData: [],
      treeDataGroup: [],
      editTreeData: [],
      tableLoading: false,
      fontColor: this.$store.state.settings.theme,
      searchForm: {
        name: '', // 试卷名称
        startDate: '', // 创建时间-开始
        endDate: '', // 计划日期-结束
        status: '', // 试卷状态 0:未发布;1:已发布;2:已停用 */
        testPaperGroupId: 0, // 试卷分组id
        page: 1,
        pageSize: 10
      },
      paperInfoList: [], // 试卷列表
      createTime: '', // 创建时间
      addPaperShow: false,
      paperTotal: 0,

      paperForm: {
        name: '', // 试卷名称
        description: '' // 试卷描述
      },
      activePaperName: '1',
      tabList: [{
        title: '未发布',
        num: 0,
        name: '0'
      }, {
        title: '已发布',
        num: 0,
        name: '1'
      }, {
        title: '已停用',
        num: 0,
        name: '2'
      }
      ],
      noPublishNum: 0, // 未发布试卷数量
      publishNum: 0, // 发布试卷数量
      stopNum: 0, // 已停用试卷数量
      planTimeForm: {
        startTime: '',
        endTime: ''
      },
      defaultProps: {
        label: 'groupName',
        children: 'children'
      },
      refreshTable: new Date().getTime(),
      activeGroup: {},
      paperDetailInfo: {}, // 详情数据
      questionsGroupList: [], // 题目分组
      previewExamShow: false, // 预览试卷弹框
      paperId: '',
      paperName: ''
    }
  },
  created () {
    this._getPaperGroupTree()// 试卷分组列表
    this._getPaperList()// 试卷列表
  },
  mounted () {
    this.fontColor = this.$store.state.settings.theme
  },
  methods: {
    // 获取试卷分组列表
    _getPaperGroupTree () {
      getPaperGroupTreeListApi().then((res) => {
        if (res.code === 200) {
          this.treeData = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    // 点击分组数据--关联右侧数据
    _clickGroupNode (data) {
      this.activeGroup = data
      this.searchForm.page = 1
      this._getPaperList(data.id)
    },
    // 试卷分组添加
    _addGroupHandle () {
      this.addGroupShow = true
    },
    // 编辑试卷分组
    _editGroupHandle (node) {
      const tempGroup = JSON.parse(JSON.stringify(this.treeData))
      // 分组不能选择子集分组，将当前分组的子集清空--start
      tempGroup.forEach((item, index) => {
        if (node.data.id === item.id) {
          item.children = []
        }
        if (item.children && item.children.length !== 0) {
          item.children.forEach((itemChild, childIndex) => {
            if (node.data.id === itemChild.id) {
              itemChild.children = []
            }
          })
        } else {
          item.children = []
        }
      })
      this.treeDataGroup = tempGroup
      // 分组不能选择子集分组，将当前分组的子集清空--end
      // 试卷分组编辑
      getPaperGroupDetailApi({ id: node.data.id }).then((res) => {
        if (res.code === 200) {
          this.editDataInfo = res.data
          this.currentNode = node
          this.editGroupShow = true
        }
      })
    },
    _submiAddGroup (item) {
      // 试卷分组添加的提交
      const groupNames = []
      item.groupNameList.forEach(item => {
        groupNames.push(item.groupName)
      })
      const obj = {
        parentId: item.parentId || 0,
        useType: item.useType,
        useDeptId: item.useDeptId,
        useDeptName: item.useDeptName,
        groupNames
      }
      getPaperGroupAddApi(obj).then(res => {
        if (res.code === 200) {
          this.addGroupShow = false
          this._getPaperGroupTree()
        }
      })
    },
    _editSubmitGroup (item) {
      // 分组编辑提交
      const obj = {
        id: this.currentNode.data.id,
        parentId: item.parentId || 0,
        groupName: item.groupName,
        useType: item.useType,
        useDeptId: item.useDeptId
      }
      getPaperGroupEditApi(obj).then(res => {
        if (res.code === 200) {
          this.editGroupShow = false
          this._getPaperGroupTree()
        }
      })
    },
    _deleteGrouphandle (node) {
      // 试卷分组删除
      getPaperGroupDeleteApi([node.data.id]).then(res => {
        if (res.code === 200) {
          this._getPaperGroupTree()
        }
      })
    },
    // 查询
    dataInit () {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      if (this.activeGroup.id) {
        this._getPaperList(this.activeGroup.id)
      } else {
        this._getPaperList()
      }
    },
    // 获取试卷列表
    _getPaperList (paperGroupId) {
      if (!this.createTime) {
        this.searchForm.startDate = ''
        this.searchForm.endDate = ''
      } else {
        this.searchForm.startDate = this.createTime[0]
        this.searchForm.endDate = this.createTime[1]
      }
      this.searchForm.status = this.activePaperName
      this.tableLoading = true
      this.searchForm.testPaperGroupId = paperGroupId || 0// 试卷分组id
      getPaperListApi({ ...this.searchForm }).then((res) => {
        if (res.code === 200) {
          this.paperInfoList = res.object.results
          this.paperTotal = res.object.total
          this.refreshTable = new Date().getTime()
        }
      }).finally(() => {
        this.tableLoading = false
      })
      const paperNumParams = {
        ...this.searchForm,
        page: undefined,
        pageSize: undefined,
        status: undefined
      }
      this._getPaperStatusNumber(paperNumParams)// 每次查询条用查询试卷状态的条数
    },
    // 获取试卷各个状态数量
    _getPaperStatusNumber (params) {
      getPaperStatusNumberApi({ ...params }).then((res) => {
        if (res.code === 200) {
          this.tabList[0].num = res.data?.notPublishedNum || 0
          this.tabList[1].num = res.data?.alreadyPublishedNum || 0
          this.tabList[2].num = res.data?.disabledNum || 0
        }
      })
    },
    // 试卷详情
    _paperDetailEditBtn (item, index) {
      this.$router.push({
        path: '/addExamPaper',
        query: {
          paperId: item.id,
          status: item.status,
          name: item.name,
          pageType: 'add'
        }
      })
    },
    // 停用试卷/发布试卷
    _updatePaperStatusBtn (item, paperStatus) {
      if (Number(item.questionNumber || 0) < 1) {
        return this.$message.error('该试卷下没有题目，不可发布！')
      }
      const params = {
        ...item,
        status: paperStatus
      }
      getPaperEditApi(params).then((res) => {
        if (res.code === 200) {
          this.$message.success('操作成功！')
          if (this.activeGroup.id) {
            this._getPaperList(this.activeGroup.id)
          } else {
            this._getPaperList()
          }
        }
      })
    },
    // 安排考试
    _arrangeExamBtn (paperItem) {
      console.log(paperItem, 'paperItem')
      this.$router.push({
        path: '/addExamArrange',
        query: {
          id: paperItem.id,
          name: paperItem.name,
          mark: paperItem.mark
        }
      })
    },
    // 删除试卷
    _paperDeleteBtn (paperItem, paperIndex) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          getPaperManyDeleteApi([paperItem.id]).then((res) => {
            if (res.code === 200) {
              this.searchForm.page = this._deleteFinalData(this.paperTotal - 1, this.searchForm.pageSize, this.searchForm.page)
              this.$message.success('删除成功！')
              if (this.activeGroup.id) {
                this._getPaperList(this.activeGroup.id)
              } else {
                this._getPaperList()
              }
            }
          })
        })
        .catch(() => {})
    },
    // 分页
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      if (this.activeGroup.id) {
        this._getPaperList(this.activeGroup.id)
      } else {
        this._getPaperList()
      }
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      if (this.activeGroup.id) {
        this._getPaperList(this.activeGroup.id)
      } else {
        this._getPaperList()
      }
    },
    // 重置--查询
    _reset () {
      this.searchForm = {
        name: '', // 试卷名称
        startDate: '', // 创建时间-开始
        endDate: '', // 计划日期-结束
        status: '', // 试卷状态 0:未发布;1:已发布;2:已停用 */
        page: 1,
        pageSize: 10
      }
      this.createTime = ''
      if (this.activeGroup.id) {
        this._getPaperList(this.activeGroup.id)
      } else {
        this._getPaperList()
      }
    },
    // 查询
    _search () {

    },
    // 切换tab
    _tabClick (tab) {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      if (this.activeGroup.id) {
        this._getPaperList(this.activeGroup.id)
      } else {
        this._getPaperList()
      }
    },
    // 创建试卷
    _createExamPaper () {
      this.addPaperShow = true
    },
    _submitAddPaper (item) {
      // 创建试卷之后跳转到编辑试卷
      this.addPaperShow = false
      this.$router.push({
        path: '/addExamPaper',
        query: {
          paperId: item,
          pageType: 'add',
          status: 0 // 创建完成之后都是未发布的状态
        }
      })
    },
    _previewPaper (row, index) {
      this.paperName = row.name
      this.paperId = String(row.id)
      this._getCurrentPaperDetail(row.id)
    },
    // 获取详情
    _getCurrentPaperDetail (paperId) {
      getPaperDetailApi({ id: paperId }).then((res) => {
        if (res.code === 200) {
          this.paperDetailInfo = JSON.parse(JSON.stringify(res.data))
          if (this.paperDetailInfo.questions.length !== 0) {
            this.paperDetailInfo.questions.forEach((groupInfo, groupIndex) => {
              this._getNeedShowData(this.paperDetailInfo.questions, groupInfo, groupIndex, 'edit')// 回显所需数据格式（整个数组，其中一个整个分组，分组下标）
            })
          }
          if (this.paperDetailInfo?.questions.length === 1 && this.paperDetailInfo?.questions[0]?.groupName === '') {
            // 不分组情况下
            this.$nextTick(() => {
              this.questionsGroupList = this.paperDetailInfo.questions
            })
            this.$set(this.questionsGroupList, 0, { ...this.questionsGroupList })// 刷新页面
          } else {
            // 分组情况下---
            this.$nextTick(() => {
              this.questionsGroupList = this.paperDetailInfo.questions
            })
            this.$set(this.questionsGroupList, 'questionInfo', this.paperDetailInfo.questions)// 刷新页面
          }
          this.$nextTick(() => {
            this.previewExamShow = true
          })
        }
      })
    },
    // 回显所需数据格式
    _getNeedShowData (groupTopicList, groupInfo, groupIndex, type) {
      groupInfo.groupIndex = groupIndex
      groupInfo.detailDeleteId = (type === 'edit' || groupInfo.detailDeleteId !== undefined) ? groupInfo.groupIndex : undefined
      groupTopicList[groupIndex].questionInfo = groupTopicList[groupIndex].questionInfo.map((topicItem, topicIndex) => {
        return {
          ...topicItem,
          detailDeleteId: (type === 'edit' || groupInfo.detailDeleteId !== undefined) ? topicItem.id : undefined,
          index: topicIndex,
          groupIndex: groupIndex,
          answerCheck: type === 'edit' ? (topicItem?.answer ? (topicItem?.answer?.split(',')) : '') : topicItem?.answerCheck,
          options: type === 'edit' ? (topicItem?.options ? (JSON.parse(topicItem?.options)) : '') : topicItem?.options,
          contentUrl: type === 'edit' ? (topicItem?.contentUrl ? (topicItem?.contentUrl?.split(',')) : '') : topicItem?.contentUrl,
          analysisUrl: type === 'edit' ? (topicItem?.analysisUrl ? (topicItem?.analysisUrl.split(',')) : '') : topicItem?.analysisUrl
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.card-pd{
  /deep/.el-card__header{
    padding: 16px 24px;
  }
  /deep/ .el-card__body{
    padding: 16px 24px;
  }
}
  /deep/.el-tabs__item.is-active{
    color:var(--font-Color)!important;
  }
  /deep/.el-tabs__item{
    color: #737373!important;
    padding: 0 16px!important;
  }
  /deep/.el-tabs__header{
    margin: 0 0 24px;
  }
  .form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 16px;
    margin-right: 32px;
  }
  /deep/.el-form-item__label{
    padding-right: 16px;
    color: rgba(0, 0, 0, 0.90);
  }
}
.create-exam{
  /deep/.el-button{
    margin-left: 0px !important;
  }
}
/deep/.el-button{
  margin-left: 16px !important;
  font-size: 14px;
  height: 32px;
}
.btn-style{
  margin-left:0px!important;
}
/deep/.el-button--default{
  color:  rgba(0, 0, 0, 0.90);
}
.plan-list-box{
  margin-top: 16px;
  padding: 0 24px 16px 24px;
  background-color: #ffffff;
  border-radius: 4px;
  // height: 650px;
  min-height:590px;
  /deep/.el-tabs__nav {
    height: 55px;
  }
  /deep/.el-tabs__item {
    line-height: 55px;
  }
  .paper-tab-pane{
    overflow-y: scroll;
    height: 56vh;
  }
}
.paper-box{
  padding: 16px 24px;
  margin-bottom: 24px;
  background: linear-gradient(to bottom,#F0F8FF,#FFFFFF);
  border: 1px solid #E7F4FF;
  border-radius: 8px;
  .paper-title{
    display: flex;
    justify-content: space-between;
    .paper-title-left{
      display: flex;
      align-items: center;
      .paper-title-mc{
        font-size: 16px;
        font-weight: 600;
        color: #333333;
      }
      .paper-title-bq{
        padding: 3px 12px;
        margin-left: 16px;
        font-size: 12px;
        color: #FFFFFF;
        border-radius: 8px 0px 8px 0px;
        background: linear-gradient(to right, #31ADF6,#5096FF);
      }
    }
  }
  .paper-content{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 12px;
    .paper-content-left{
      .paper-content-left-ms{
        margin-bottom: 12px;
        width: 450px;
        font-size: 14px;
        color: #666666;
      }
      .paper-content-left-xx{
        display: flex;
        align-items: center;
        font-size: 12px;
        .line{
          margin: 0 12px;
          width: 1px;
          height: 16px;
          background-color: #DCDCDC;
        }
      }
    }
  }
}
.exam-dialog{
  /deep/.el-dialog{
    width: 400px;
    height: 276px;
  }
  /deep/.el-dialog__title{
    font-size: 16px;
    color: hsla(0, 0%, 0%, 0.9);
    font-weight: 500;
  }
  /deep/.el-dialog__body{
    padding: 20px 30px 14px 24px;
  }
}
</style>
