<!--
 * @Descripttion:
 * @version:
 * @Author: guomengxia
 * @Date: 2023-11-19 13:36:07
 * @LastEditors: guomengxia
 * @LastEditTime: 2024-12-20 11:29:12
-->
<template>
  <div class="resourceManage fx-col fx-1">
    <template>
      <el-card>
        <div slot="header">
          <el-form class="form-item-no-mb" ref="searchForm" inline :model="searchForm" size="small">
            <el-form-item label="题库名称">
              <el-input v-model="searchForm.questionBankName" clearable placeholder="请输入题库名称"></el-input>
            </el-form-item>
            <el-form-item style="margin-left:22px;">
              <el-button @click="_resetBtn()">重置</el-button>
              <el-button style="margin-left:16px;" type="primary" @click="dataInit()">查询</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button type="primary" @click="testBaseHandle('add')"><i class="el-icon-plus"></i><span>新增题库</span></el-button>
        </div>
      </el-card>
      <div class="fx-row mt16 bac-fff fx-1">
        <!-- 题库分组 -->
        <ResourceGrouping
          :treeData="treeData"
          :title="titleText"
          :defaultProps="defaultProps"
          @editGroupHandle="_editGroupHandle"
          @deleteGrouphandle="_deleteGrouphandle"
          @addGroupHandle="_addGroupHandle"
          @clickGroupNode="_clickGroupNode"
        />
        <div class="w200 fx-1">
          <el-table
            :header-cell-style="cellHeaderStyle"
            :cell-style="cellStyle"
            class="index-list-table"
            :data="tableData"
            height="calc(100vh - 350px)"
            v-loading="tableLoading">
            <el-table-column prop="questionBankName" label="题库名称">
              <template slot-scope="scope">
                {{scope.row.questionBankName||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="questionBankGroupName" label="题库分组">
              <template slot-scope="scope">
                {{scope.row.questionBankGroupName||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="sumQuestionNum" label="总题数">
              <template slot-scope="scope">
                {{scope.row.sumQuestionNum||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="sumSingleChoiceNum" label="单项选择">
              <template slot-scope="scope">
                {{scope.row.sumSingleChoiceNum||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="sumMultipleChoiceNum" label="多项选择">
              <template slot-scope="scope">
                {{scope.row.sumMultipleChoiceNum||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="sumJudgmentNum" label="判断题">
              <template slot-scope="scope">
                {{scope.row.sumJudgmentNum||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="sumGapFillingNum" label="填空题">
              <template slot-scope="scope">
                {{scope.row.sumGapFillingNum||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="sumEssayQuestionNum" label="问答题">
              <template slot-scope="scope">
                {{scope.row.sumEssayQuestionNum||'—'}}
              </template>
            </el-table-column>
            <el-table-column prop="createName" label="创建人"></el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button type="text" class="text-btn mr24" @click="_manageHandle(scope.row)">管理</el-button>
                <el-button type="text" class="text-btn mr24" @click="testBaseHandle('edit',scope.row)">编辑</el-button>
                <el-button type="text" class="text-btn" @click="_deleteTopicBtn(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            class="fx-row-end pt16"
            @size-change="_handleSizeChange"
            @current-change="_handleCurrentChange"
            :current-page="searchForm.page"
            :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="searchForm.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tableTotal">
          </el-pagination>
        </div>
      </div>
    </template>
    <!--
    <edit-file v-if="editFileShow" ref="editFile"></edit-file>
    <preview-file v-if="previewFileShow" ref="previewFile"></preview-file> -->
    <add-or-edit-test-base
      v-if="addOrEditTestBaseShow"
      ref="addOrEditTestBase"
      :treeData="treeData"
      :defaultProps="defaultProps"
      @addTopicListData="_addTopicListBtn"
      @editTopicListData="_editTopicListBtn"
    ></add-or-edit-test-base>
    <AddGroup
      v-if="addGroupShow"
      :addGroupShow="addGroupShow"
      :treeData="treeData"
      :defaultProps="defaultProps"
      @submiAddGroup="_submiAddGroup"
      ref="addGroup"
    ></AddGroup>
    <!-- 题库分组编辑 -->
    <EditGroup
      v-if="editGroupShow"
      ref="editGroup"
      :editGroupShow="editGroupShow"
      :editDataInfo="editDataInfo"
      :treeData="treeDataGroup"
      :defaultProps="defaultProps"
      :currentNode="currentNode"
      @editSubmitGroup="_editSubmitGroup"
      pageValueType='normalGroup'
    ></EditGroup>
    <!-- 分组删除 -->
    <DeleteGroup v-if="deleteGroupShow" :deleteGroupShow="deleteGroupShow" />
  </div>
</template>
<script>
import addOrEditTestBase from './components/addOrEditTestBase.vue'
import EditGroup from '@/components/editGroup/index.vue'
import ResourceGrouping from '@/components/resourceGrouping/index.vue'
import AddGroup from '@/components/addGroup/index.vue'
import DeleteGroup from '@/components/deleteGroup/index.vue'
import tableFirstCol from '@/mixins/tableFirstCol'
import deleteFinalData from '@/mixins/deleteFinalData'
// import uploadFile from './compnents/uploadFile.vue'
import {
  questionBankGroupTreeListApi,
  questionBankGroupAddApi,
  questionBankGroupEditApi,
  questionBankGroupDeleteApi,
  questBankManagePageListApi,
  questBankManageAddApi,
  questBankManageEditApi,
  questBankManageDeleteApi,
  questionBankGroupDetailApi
} from '@/api/examApi'
export default {
  mixins: [tableFirstCol, deleteFinalData],
  components: {
    addOrEditTestBase,
    EditGroup,
    AddGroup,
    DeleteGroup,
    ResourceGrouping
  },
  data () {
    return {
      titleText: '题库分组',
      addGroupShow: false,
      tableLoading: false,
      treeData: [],
      treeDataGroup: [],
      editTreeData: [],
      tableData: [], // 题库管理列表
      editDataInfo: {}, // 编辑数据信息
      searchForm: {
        questionBankName: '',
        page: 1,
        pageSize: 10
      },
      defaultProps: {
        label: 'groupName',
        children: 'children'
      },
      tableTotal: 0,
      editGroupShow: false,
      deleteGroupShow: false,
      addOrEditTestBaseShow: false,
      currentNode: null,
      activeGroup: {}
    }
  },
  created () {
    this._getQuestionBankTree()
    this._getTopicList()
  },
  methods: {
    // 获取题库分组列表
    _getQuestionBankTree () {
      questionBankGroupTreeListApi().then((res) => {
        console.log(res, '题库分组')
        if (res.code === 200) {
          this.treeData = JSON.parse(JSON.stringify(res.data))
        }
      })
    },
    _editGroupHandle (node) {
      const tempGroup = JSON.parse(JSON.stringify(this.treeData))
      // 分组不能选择子集分组，将当前分组的子集清空--start
      tempGroup.forEach((item, index) => {
        if (node.data.id === item.id) {
          item.children = []
        }
        if (item.children && item.children.length !== 0) {
          item.children.forEach((itemChild, childIndex) => {
            if (node.data.id === itemChild.id) {
              itemChild.children = []
            }
          })
        } else {
          item.children = []
        }
      })
      this.treeDataGroup = tempGroup
      // 分组不能选择子集分组，将当前分组的子集清空--end
      // 题库分组编辑
      questionBankGroupDetailApi({ id: node.data.id }).then((res) => {
        if (res.code === 200) {
          this.editDataInfo = res.data
          this.currentNode = node
          this.editGroupShow = true
        }
      })
    },
    // 分组添加
    _addGroupHandle () {
      this.addGroupShow = true
    },
    // 点击分组数据--关联右侧数据
    _clickGroupNode (data) {
      this.activeGroup = data
      this.searchForm.page = 1
      this._getTopicList(data.id)
    },
    _submiAddGroup (item) {
      // 分组添加的提交
      const groupNames = []
      item.groupNameList.forEach(item => {
        groupNames.push(item.groupName)
      })
      const obj = {
        parentId: item.parentId || 0,
        useType: item.useType,
        useDeptId: item.useDeptId,
        useDeptName: item.useDeptName,
        groupNames
      }
      questionBankGroupAddApi(obj).then(res => {
        if (res.code === 200) {
          this.addGroupShow = false
          this._getQuestionBankTree()
        }
      })
    },
    _editSubmitGroup (item) {
      // 分组编辑提交
      const obj = {
        id: this.currentNode.data.id,
        parentId: item.parentId || 0,
        groupName: item.groupName,
        useType: item.useType,
        useDeptId: item.useDeptId
      }
      questionBankGroupEditApi(obj).then(res => {
        if (res.code === 200) {
          this.editGroupShow = false
          this._getQuestionBankTree()
        }
      })
    },
    _deleteGrouphandle (node) {
      // 题库分组删除
      questionBankGroupDeleteApi([node.data.id]).then(res => {
        if (res.code === 200) {
          this._getQuestionBankTree()
        }
      })
    },
    _manageHandle (row) {
      console.log(row)
      this.$router.push({
        path: '/topicManage',
        query: {
          questionBankId: row.questionBankId
        }
      })
    },
    testBaseHandle (type, index) {
      this.addOrEditTestBaseShow = true
      this.$nextTick(() => {
        this.$refs.addOrEditTestBase.init(type, index)
      })
    },
    editGroupHandle (node, data) {
      console.log(node, data)
      this.editGroupShow = true
      this.$nextTick(() => {
        this.$refs.editGroup.init()
      })
    },

    // 删除分组
    _deleteGroupLevel (node, data) {
      console.log(node, data)
      const parent = node.parent
      const children = parent.data.children || parent.data
      const index = children.findIndex(d => d.id === data.id)
      children.splice(index, 1)
    },
    // 题库列表
    _getTopicList (questionBaseId) { // 关联左侧分组和右侧列表---传参，默认不传实参
      this.tableLoading = true
      const params = {
        questionBankGroupId: questionBaseId || 0,
        ...this.searchForm
      }
      questBankManagePageListApi({ ...params }).then((res) => {
        if (res.code === 200) {
          this.tableData = res.object.results
          this.tableTotal = res.object.total
        }
      }).finally(() => {
        this.tableLoading = false
      })
    },

    // 新增题库
    _addTopicListBtn (data) {
      questBankManageAddApi(data).then((res) => {
        if (res.code === 200) {
          this.$message.success('新增成功！')
          this.addOrEditTestBaseShow = false
          if (this.activeGroup.id) {
            this._getTopicList(this.activeGroup.id)
          } else {
            this._getTopicList()
          }
        }
      })
    },
    // 编辑题库
    _editTopicListBtn (data) {
      questBankManageEditApi(data).then((res) => {
        if (res.code === 200) {
          this.$message.success('编辑成功！')
          this.addOrEditTestBaseShow = false
          if (this.activeGroup.id) {
            this._getTopicList(this.activeGroup.id)
          } else {
            this._getTopicList()
          }
        }
      })
    },
    // 删除题库
    _deleteTopicBtn (row) {
      this.$confirm('确定要删除吗？', '提示', {
        type: 'warning'
      })
        .then(() => {
          questBankManageDeleteApi([row.questionBankId]).then((res) => {
            if (res.code === 200) {
              this.searchForm.page = this._deleteFinalData(this.tableTotal - 1, this.searchForm.pageSize, this.searchForm.page)
              this.$message.success('删除成功！')
              if (this.activeGroup.id) {
                this._getTopicList(this.activeGroup.id)
              } else {
                this._getTopicList()
              }
            }
          })
        })
        .catch(() => {})
    },
    dataInit () {
      this.searchForm.page = 1
      this.searchForm.pageSize = 10
      if (this.activeGroup.id) {
        this._getTopicList(this.activeGroup.id)
      } else {
        this._getTopicList()
      }
    },
    _resetBtn () {
      this.searchForm = {
        questionBankName: '',
        page: 1,
        pageSize: 10
      }
      if (this.activeGroup.id) {
        this._getTopicList(this.activeGroup.id)
      } else {
        this._getTopicList()
      }
      this.$message.success('重置成功！')
    },
    _handleSizeChange (val) {
      this.searchForm.pageSize = val
      if (this.activeGroup.id) {
        this._getTopicList(this.activeGroup.id)
      } else {
        this._getTopicList()
      }
    },
    _handleCurrentChange (val) {
      this.searchForm.page = val
      if (this.activeGroup.id) {
        this._getTopicList(this.activeGroup.id)
      } else {
        this._getTopicList()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
/deep/.el-card__body, .el-main{
  padding: 16px 16px 32px 8px;
}
.form-item-no-mb {
  /deep/.el-form-item {
    margin-bottom: 16px;
  }
}
.resourceManage {
  .lf-true {
    width: 260px;
    margin-right: 8px;
  }
}
</style>
